<template>
  <div class="sectionThree">
    <div class="arrow_left" @click="leftClick">
      <img src="@/assets/images/home/slide_left.png" alt="" />
    </div>
    <div class="arrow_right" @click="rightClick">
      <img src="@/assets/images/home/slide_right.png" alt="" />
    </div>
    <div
      class="search_policy"
      :class="{ bannerActive: currentIndex === index }"
      v-for="(item, index) in arrList"
      :key="index"
    >
      <div class="main_width">
        <div class="main_container">
          <div class="left_content">
            <div class="title queue-left">{{ item.title }}</div>
            <p class="queue-left animate_delay01">{{ item.content }}</p>
            <ul class="policy_icon queue-left animate_delay02">
              <li v-for="(item2, index2) in item.policyIcon" :key="index2">
                <img :src="item2.picImg" alt="" />
                <span>{{ item2.picContent }}</span>
              </li>
            </ul>
            <div
              class="moreBtn queue-left animate_delay03"
              @click="$router.push(jumpTo[index])"
            >
              了解更多
              <img src="@/assets/images/home/arrow_right.png" alt="" />
            </div>
            <div class="dot">
              <i
                v-for="(item, index) in arrList.length"
                :key="index"
                :class="{ active: currentIndex === index }"
              ></i>
            </div>
          </div>
          <div class="right_content">
            <img
              class="policy_phone"
              src="@/assets/images/home/policy_phone.png"
              alt=""
            />
            <img class="screen_pic opcity-show" :src="item.screenPic" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentIndex: 0, // 轮播图当前下标
      arrList: [
        {
          title: '查政策',
          content: '企业慧点已经汇集适用于深圳企业的超过1000+条政府扶持政策和措施,依托于线下专业团队逐条政策解析贴标，并运用大数据和人工智能技术，对深圳全市300+万市场主体实现政策关联匹配，实现惠企政策的精准推荐，目前累计用户超过20000+人。',
          policyIcon: [
            {
              picImg: require('@/assets/images/home/policy_icon1.png'),
              picContent: '政策智能匹配'
            },
            {
              picImg: require('@/assets/images/home/policy_icon2.png'),
              picContent: '每日政策推送'
            },
            {
              picImg: require('@/assets/images/home/policy_icon3.png'),
              picContent: '政策订阅'
            }
          ],
          screenPic: require('@/assets/images/home/policy_screen.png')
        },
        {
          title: '查招标',
          content: '慧点招标一款包含招/中标查询、企业中标历史查询、客制化关键词订阅及通知、收藏监控等多项关键功能。通过应用大数据、人工智能技术为用户提供及时，准确的招投标信息，从而使用户可以轻松的筛选对自己有用的标讯，不再错失商机。',
          policyIcon: [
            {
              picImg: require('@/assets/images/home/bidding_icon1.png'),
              picContent: '最新标讯'
            },
            {
              picImg: require('@/assets/images/home/bidding_icon2.png'),
              picContent: '标讯订阅'
            },
            {
              picImg: require('@/assets/images/home/bidding_icon3.png'),
              picContent: '招标项目追踪'
            }
          ],
          screenPic: require('@/assets/images/home/bidding_screen.png')
        },
        {
          title: '企业服务',
          content: '企业慧点为企业成长提供贴身服务的一站式企业服务平台，提供无忧顺畅高效的沟通渠道，从咨询到方案落地实施，提供全过程顾问式服务，助力企业远航。业务涵盖政策咨询、审计服务、品牌资质、税务筹划、工商财税、法律咨询等多元企业服务。',
          policyIcon: [
            {
              picImg: require('@/assets/images/home/enterprise_icon1.png'),
              picContent: '金融服务'
            },
            {
              picImg: require('@/assets/images/home/enterprise_icon2.png'),
              picContent: '工商服务'
            },
            {
              picImg: require('@/assets/images/home/enterprise_icon3.png'),
              picContent: '税务筹划'
            }
          ],
          screenPic: require('@/assets/images/home/enterprise_screen.png')
        },
        {
          title: '金融服务',
          content: '企业慧点为企业专属打造银行专区，打通政银企三端信息及沟通路径，客户经理直接服务于企业，贷款后政府惠企补贴无缝对接，提高企业办事效率以及降低企业用户的资金使用成本等。',
          policyIcon: [
            {
              picImg: require('@/assets/images/home/finance_icon1.png'),
              picContent: '银行专属定制产品'
            },
            {
              picImg: require('@/assets/images/home/finance_icon2.png'),
              picContent: '智能推送贷款惠企补贴'
            },
            {
              picImg: require('@/assets/images/home/finance_icon3.png'),
              picContent: '一对一客户经理服务'
            }
          ],
          screenPic: require('@/assets/images/home/finance_screen.png')
        }
      ],
      jumpTo: ['/policyList', '/bidList', '/entService/freeTrial', '/finance']
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = e.target.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop >= 1000) {
        const leftContent = document.querySelectorAll('.left_content')
        leftContent.forEach(item => {
          item.style.display = 'block'
        })
      }
    },
    // 轮播左按钮
    leftClick () {
      this.currentIndex--
      if (this.currentIndex < 0) {
        this.currentIndex = 3
      }
    },
    // 轮播右按钮
    rightClick () {
      if (this.currentIndex >= 3) {
        this.currentIndex = 0
      } else {
        this.currentIndex++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerActive {
  display: block !important;
}
// 第三模块
.sectionThree {
  position: relative;
  display: flex;
  width: 100%;
  height: 820px;
  &:hover .arrow_left,
  &:hover .arrow_right {
    opacity: 1;
  }
  .arrow_left,
  .arrow_right {
    transition: all 0.4s;
    opacity: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    &:hover {
      background-color: #d3b98a;
    }
  }
  .arrow_left {
    position: absolute;
    left: 10%;
    top: 350px;
  }
  .arrow_right {
    position: absolute;
    right: 10%;
    top: 350px;
  }
  .search_policy {
    width: 100%;
    padding-top: 94px;
    flex-shrink: 0;
    display: none;
    .main_width {
      height: 100%;
      .main_container {
        height: 100%;
        position: relative;
        padding-left: 10%;
        .left_content {
          display: none;
        }
        .right_content {
          position: absolute;
          left: 42%;
          top: -194px;
          .policy_phone {
            position: relative;
          }
          .screen_pic {
            position: absolute;
            left: 195px;
            top: 25px;
          }
        }
        .title {
          font-size: 44px;
          font-weight: 500;
          line-height: 64px;
          color: #333333;
        }
        p {
          width: 474px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          color: #333333;
          margin-top: 22px;
        }
        .policy_icon {
          width: 389px;
          display: flex;
          justify-content: space-between;
          margin-top: 78px;
          li {
            width: 87px;
            text-align: center;
            span {
              display: inline-block;
              margin-top: 25px;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }
          }
        }
        .moreBtn {
          width: 184px;
          height: 47px;
          line-height: 47px;
          border: 1px solid #707070;
          border-radius: 3px;
          text-align: center;
          font-size: 18px;
          color: #333333;
          margin-top: 60px;
          cursor: pointer;
          &:hover {
            border: 1px solid rgba(112, 112, 112, 0.5);
            color: rgba(51, 51, 51, 0.5);
          }
          img {
            display: inline-block;
            margin-left: 21px;
          }
        }
        .dot {
          position: absolute;
          bottom: 70px;
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #e9e9e9;
            border-radius: 50%;
            margin-right: 13px;
          }
          .active {
            background-color: #636363;
          }
        }
      }
    }
  }
}
// 媒体查询
@media (max-width: 1700px) {
  .arrow_left {
    left: 0 !important;
  }
  .arrow_right {
    right: 0 !important;
  }
}
</style>
