<template>
  <div id="home" class="opcity-show">
    <!-- 第一模块 -->
    <sectionOne />
    <!-- 第二模块 -->
    <sectionTwo />
    <!-- 第三模块 -->
    <sectionThree />
    <!-- 第四模块 -->
    <sectionFour />
    <!-- 第五模块 -->
    <sectionFive />
    <!-- 第六模块 -->
    <sectionSix />
    <!-- 新闻资讯 -->
    <newsInfo :firstList="firstList" :newsList="newsList" />
    <serveBar />
    <!-- 第七模块 -->
    <sectionSeven />
    <!-- 进场动画 -->
    <playAnimation />
  </div>
</template>

<script>
import sectionOne from './components/sectionOne.vue'
import sectionTwo from './components/sectionTwo.vue'
import sectionThree from './components/sectionThree.vue'
import sectionFour from './components/sectionFour.vue'
import sectionFive from './components/sectionFive.vue'
import sectionSix from './components/sectionSix.vue'
import sectionSeven from './components/sectionSeven.vue'
import serveBar from './components/serveBar.vue'
import newsInfo from './components/newsInfo.vue'
import playAnimation from '@/common/playAnimation.vue'
import { searchByNewsType } from '@/api/news'
export default {
  components: {
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    serveBar,
    newsInfo,
    playAnimation
  },
  data () {
    return {
      firstList: {},
      newsList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      const params = {
        paginate: {
          currentPageNum: 1,
          recordsPerPage: 8
        }
      }
      const res = await searchByNewsType(params)
      if (res.data.data && res.data.data.length) {
        this.firstList = res.data.data[0]
        this.newsList = res.data.data.slice(1, res.data.data.length)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  transition: all 0.2s;
  //   width: 1920px;
}
:deep(.el-carousel__arrow) {
  width: 145px;
  height: 113px;
  top: unset;
  bottom: 0;
  border-radius: 0;
  background-color: #fff;
  transform: translateY(0);
}
:deep(.el-carousel__arrow--right) {
  right: 0;
}
:deep(.el-carousel__arrow--left) {
  left: unset;
  right: 145px;
}
:deep(.el-icon-arrow-right),
:deep(.el-icon-arrow-left) {
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  color: #000;
}
:deep(.el-icon-arrow-left) {
  border-right: 1px solid #eaeaea;
}
:deep(.el-carousel__indicators--horizontal) {
  left: 120px;
  bottom: 15px;
  z-index: 20;
}
:deep(.el-carousel__indicator--horizontal) {
  padding: 12px 6px;
}
:deep(.el-carousel__button) {
  width: 44px;
}
</style>
