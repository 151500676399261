<template>
  <div class="serveBar">
    <ul class="serveBar_container main_width">
      <li>
        <img src="@/assets/images/home/serveBar_icon1.png" alt="" />
        <span>7*24小时服务</span>
      </li>
      <li>
        <img src="@/assets/images/home/serveBar_icon2.png" alt="" />
        <span>一对一企业管家服务</span>
      </li>
      <li>
        <img src="@/assets/images/home/serveBar_icon3.png" alt="" />
        <span>服务过程全流程监督</span>
      </li>
      <li>
        <img src="@/assets/images/home/serveBar_icon4.png" alt="" />
        <span>退款售后无忧</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.serveBar {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  .serveBar_container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    li {
      display: flex;
      align-items: center;
      span {
        margin-left: 5px;
        color: #fff;
      }
    }
  }
}
</style>
