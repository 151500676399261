<template>
  <div class="sectionTwo">
    <div class="central main_width">
      <div class="top_waist">
        <div class="waist_content" ref="waist_content">
          <div class="bidding">
            <img
              class="queue-bottom"
              src="@/assets/images/home/bidding.png"
              alt=""
            />
            <div class="title queue-bottom animate_delay01">政策、标讯</div>
            <div class="bidding_content queue-bottom animate_delay02">
              汇聚政府各类政策讯息，一键智能匹配，多种方式检索，最新的招标/中标讯息
            </div>
          </div>
          <img
            class="add_btn queue-bottom"
            src="@/assets/images/home/add_btn.png"
            alt=""
          />
          <div class="serve">
            <div class="title queue-bottom">数字化企业服务工具</div>
            <!-- <div class="bidding_content queue-bottom animate_delay01">
              为企业提供数字化工具
            </div> -->
            <div class="serve_icon queue-bottom animate_delay02">
              <img
                class="serveIcon_top"
                src="@/assets/images/home/serve_top.png"
                alt=""
              />
              <img
                class="serveIcon_bottom"
                src="@/assets/images/home/serve_bottom.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content" ref="content">
        <h3 class="queue-bottom animate_delay01">热门产品和服务</h3>
        <span class="queue-bottom animate_delay02">
          围绕中小企业初创、发展、成长周期，打造数字化产品和服务
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = e.target.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop >= 200) {
        this.$refs.waist_content.style.display = 'flex'
      }
      if (scrollTop >= 500) {
        this.$refs.content.style.display = 'block'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 第二模块
.sectionTwo {
  width: 100%;
  height: 560px;
  background-color: #000;
  text-align: center;
  .central {
    position: relative;
  }
  .top_waist {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    width: 1200px;
    height: 316px;
    background-color: #202020;
    display: flex;
    align-items: center;
    .waist_content {
      display: none;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 65px;
      .title {
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 12px;
      }
      .bidding_content {
        font-size: 20px;
        line-height: 34px;
        color: #ffffff;
      }
      .bidding {
        width: 424px;
        img {
          display: inline-block;
          margin-bottom: 27px;
        }
      }
      .serve {
        width: 480px;
        .serve_icon {
          width: 100%;
          height: 146px;
          position: relative;
          margin-top: 50px;
          .serveIcon_top {
            position: absolute;
            top: 0;
            left: 0;
          }
          .serveIcon_bottom {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
  .content {
    display: none;
    padding-top: 258px;
    h3 {
      font-size: 60px;
      font-weight: 500;
      color: #ffffff;
    }
    span {
      display: block;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 20px;
    }
  }
}
</style>
