<template>
  <div class="sectionSeven">
    <div class="content">现在注册可获取企业服务<span>300元</span>抵扣券</div>
    <div class="getBtn" @click="subInfo">立即咨询</div>
    <subInfo v-model="isShow"></subInfo>
    <successForm v-model="isSuccess"></successForm>
  </div>
</template>

<script>
import subInfo from '@/common/subInfo'
import successForm from '@/common/successForm'
export default {
  components: {
    subInfo,
    successForm
  },
  data () {
    return {
      isShow: false,
      isSuccess: false
    }
  },
  methods: {
    subInfo () {
      this.isShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
// 第七模块
.sectionSeven {
  width: 100%;
  height: 287px;
  background: url("~assets/images/home/ticket_bg.png") no-repeat 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    font-size: 32px;
    color: #ffffff;
    margin-right: 54px;
    span {
      color: #cca766;
    }
  }
  .getBtn {
    width: 200px;
    height: 54px;
    line-height: 54px;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 9px 37px rgba(41, 41, 41, 0.45);
    border-radius: 2px;
    font-size: 22px;
    font-weight: 500;
    color: #230a01;
    text-align: center;
    cursor: pointer;
  }
}
</style>
