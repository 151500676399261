import request from '@/utils/request'

// 新闻资讯列表
export function searchByNewsType (data = {}) {
  return request({
    url: '/pla/news/searchByNewsType',
    method: 'post',
    data
  })
}

// 新闻资讯详情
export function newsDetail (data = {}) {
  return request({
    url: '/pla/news/detail',
    method: 'post',
    data
  })
}
