<template>
  <div class="sectionSix">
    <div class="customer main_width">
      <h2>客户案例</h2>
      <span>客户的信赖之选</span>
      <div class="banner">
        <el-carousel trigger="click" height="540px" arrow="always">
          <el-carousel-item v-for="(item, index) in arrList" :key="index">
            <img :src="item.bg" alt="" />
            <div class="banner_left">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="content">
                {{ item.content }}
              </div>
              <ul class="achievement">
                <li>发明专利{{ item.invent }}项</li>
                <li>软件著作{{ item.work }}项</li>
              </ul>
              <ul class="subsidy">
                <li>
                  <div class="top">{{ item.subsidy }}项</div>
                  <div class="bottom">累计获得补贴</div>
                </li>
                <li>
                  <div class="top">{{ item.money }}万元</div>
                  <div class="bottom">共计</div>
                </li>
              </ul>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="button"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      arrList: [
        {
          title: '深圳市**信息技术有限公司',
          bg: require('@/assets/images/home/customer_banner1.jpg'),
          content:
            '公司致力于智慧水利、智慧应急、智慧安全园区领域的产品研发与销售，是“国家级高新技术企业”，公司获得了国家工业与信息化部颁发的“计算机信息系统集成贰级资质”，水利部颁发的水文、水资源调查评价乙级资质，国家质检总局颁发的“全国工业产品生产许可证”，中国水利工程协会颁发的“AA级企业信用证书”',
          invent: '15',
          work: '90',
          subsidy: '30',
          money: '447.46'
        },
        {
          title: '深圳市**信息技术有限公司',
          bg: require('@/assets/images/home/customer_banner2.jpg'),
          content:
            '致力于通过灵活稳定的业务经验和技术架构，让银行和其他金融企业享有互联网金融及支付领域的专业系统服务，互联网金融及支付领域的专业系统服务。秉承“专注创造产品价值”的服务核心，为客户提供一站式产品、技术解决方案服务。',
          invent: '68',
          work: '12',
          subsidy: '18',
          money: '91.72'
        },
        {
          title: '深圳市**信息技术有限公司',
          bg: require('@/assets/images/home/customer_banner3.jpg'),
          content:
            '公司是一家专注数据中台和资管核心业务解决方案的金融科技公司。核心产品为赢和资管平台，服务于公募基金、证券、保险、信托、银行理财、期货等金融机构。以专业的金融业务积累为引导，以大数据和智能AI技术为支撑，为金融机构数字化转型赋能。',
          invent: '200',
          work: '10',
          subsidy: '40',
          money: '1020.74'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// 第六模块
.sectionSix {
  // width: 100%;
  // height: 960px;
  text-align: center;
  padding-top: 93px;
  .customer {
    h2 {
      font-size: 60px;
      font-weight: 500;
      color: #333333;
      line-height: 97px;
    }
    span {
      font-size: 20px;
      color: #333333;
      margin-top: 11px;
    }
    .banner {
      position: relative;
      width: 100%;
      height: 540px;
      margin-top: 46px;
      img {
        width: 100%;
        height: 100%;
      }
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        z-index: -1;
      }
      .banner_left {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        color: #fff;
        text-align: left;
        padding: 47px 84px 0 42px;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
        .title {
          font-size: 28px;
        }
        .content {
          margin-top: 34px;
          font-size: 14px;
          line-height: 35px;
        }
        .achievement {
          display: flex;
          margin-top: 30px;
          li {
            padding: 0 13px;
            &:first-child {
              border-right: 1px solid rgba(255, 255, 255, 0.2);
              padding-left: 0;
            }
          }
        }
        .subsidy {
          display: flex;
          margin-top: 40px;
          li {
            margin-right: 45px;
            .top {
              font-size: 33px;
              font-weight: 500;
              line-height: 52px;
            }
            .bottom {
              font-size: 16px;
              line-height: 31px;
            }
          }
        }
      }
    }
  }
}
</style>
