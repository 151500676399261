<template>
  <div class="sectionFour">
    <ul class="char_container">
      <li class="char_block" @click="toCollege">
        <div class="block_post">
          <img
            class="post_pic"
            src="@/assets/images/home/academy1.png"
            alt=""
          />
        </div>
        <div class="block_info">
          <h3 class="block_title queue-bottom animate_delay01">
            慧点学院《政策解读专区》
          </h3>
          <ul class="details queue-bottom animate_delay02">
            <li>最新政策解读</li>
            <li>政策申报技巧分享</li>
          </ul>
        </div>
      </li>
      <li class="char_block" @click="toCollege">
        <div class="block_post">
          <img
            class="post_pic"
            src="@/assets/images/home/academy2.png"
            alt=""
          />
        </div>
        <div class="block_info">
          <h3 class="block_title queue-bottom animate_delay03">
            慧点学院《活动专区》
          </h3>
          <ul class="details queue-bottom animate_delay04">
            <li>线上+线下惠企活动</li>
            <li>覆盖深圳地区180多个园区</li>
          </ul>
        </div>
      </li>
      <li class="char_block" @click="toCollege">
        <div class="block_post">
          <img
            class="post_pic"
            src="@/assets/images/home/academy3.png"
            alt=""
          />
        </div>
        <div class="block_info" ref="block_info">
          <h3 class="block_title queue-bottom animate_delay05">
            慧点学院《内容专区》
          </h3>
          <ul class="details queue-bottom animate_delay06">
            <li>100+企业经营干货</li>
            <li>多行业研究报告</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = e.target.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop >= 1700) {
        const blockInfo = document.querySelectorAll('.block_info')
        blockInfo.forEach(item => {
          item.style.display = 'block'
        })
      }
    },
    toCollege () {
      this.$router.push('/college')
    }
  }
}
</script>

<style lang="scss" scoped>
// 第四模块
.sectionFour {
  .char_container {
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    .char_block {
      flex: 1;
      overflow: hidden;
      position: relative;
      .block_post {
        position: relative;
        transition: all 0.8s;
        width: 100%;
        .post_pic {
          width: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #000;
          opacity: 0.5;
        }
      }
      .block_info {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 10% 0 0 9%;
        color: #fff;
        .block_title {
          font-size: 28px;
          font-weight: 400;
        }
        .details {
          position: relative;
          margin-top: 30px;
          li {
            font-size: 14px;
            line-height: 40px;
            list-style: disc;
            margin-left: 20px;
          }
        }
        .details::after {
          content: '';
          width: 0;
          height: 3px;
          background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
          box-shadow: 0px 9px 37px rgba(41, 41, 41, 0.45);
          position: absolute;
          left: 0;
          bottom: -100%;
          display: block;
          transition: 0.5s;
        }
      }
    }
    .char_block:hover .block_post {
      transform: scale(1.1);
      &::after {
        opacity: 0.1;
      }
    }
    .char_block:hover .block_info .details::after {
      width: 36px;
    }
  }
}
// 媒体查询
@media (max-width: 1700px) {
  .details::after {
    bottom: -50% !important;
  }
}
</style>
