<template>
  <div class="mask opcity-hide">
    <h2 class="progress-left">
      <span class="display-show">找</span>
      <span class="display-show animate_delay005">补</span>
      <span class="display-show animate_delay02">贴</span>
      <span class="display-show animate_delay025">找</span>
      <span class="display-show animate_delay015">业</span>
      <span class="mr display-show animate_delay03">务</span>
      <span class="display-show animate_delay01">找</span>
      <span class="display-show animate_delay015">企</span>
      <span class="display-show animate_delay02">业</span>
      <span class="display-show animate_delay01">慧</span>
      <span class="display-show">点</span>
    </h2>
    <div class="streak"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #131313;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 45px;
    font-weight: 500;
    color: #ffffff;
    z-index: 100;
  }
  .mr {
    display: inline-block;
    margin-right: 30px;
  }
  .streak {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #212121;
    animation-delay: 0.6s;
  }
}
</style>
