<template>
  <div class="newsInfo main_width">
    <h2 class="title">资讯动态</h2>
    <div class="content">
      <div class="leftCon">
        <img class="bg" src="@/assets/images/home/newsBg.png" alt="" />
        <div class="bottomCon">
          <span class="txt" @click="toDetail(firstList.newsId)">{{
            firstList.title
          }}</span>
        </div>
      </div>
      <ul class="newList">
        <li v-for="(item, index) in newsList" :key="index">
          <img class="serialNum" :src="item.serialImg" alt="" />
          <div class="new_content">
            <div class="title" @click="toDetail(item.newsId)">
              {{ item.title }}
            </div>
            <span class="date">{{ item.releaseDate }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="moreBtn" @click="toNewsList">
      了解更多
      <img src="@/assets/images/home/arrow_right.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    newsList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    newsList () {
      this.newsList.forEach((item, index) => {
        item.serialImg = require(`@/assets/images/home/new0${index + 1}.png`)
      })
    }
  },
  data () {
    return {
    }
  },
  methods: {
    toDetail (newsId) {
      this.$router.push(`/newsDetail?newsId=${newsId}`)
    },
    toNewsList () {
      this.$router.push('/news')
    }
  }
}
</script>

<style lang="scss" scoped>
.newsInfo {
  margin-top: 88px;
  margin-bottom: 82px;
  .title {
    font-size: 60px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  .content {
    display: flex;
    margin-top: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    .leftCon {
      position: relative;
      .bg {
        width: 716px;
        height: 456px;
      }
      .bottomCon {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 92px;
        line-height: 92px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0px 0px 0px 10px;
        padding: 0 60px 0 38px;
        .txt {
          display: inline-block;
          width: 100%;
          font-size: 22px;
          font-family: Microsoft YaHei;
          color: #ffffff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
    .newList {
      flex: 1;
      padding: 25px 70px 0 36px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .serialNum {
          width: 26px;
          height: 26px;
          margin-right: 18px;
        }
        .new_content {
          .title {
            width: 350px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            color: #333333;
            margin-bottom: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            cursor: pointer;
            &:hover {
              color: #e5ba71;
            }
          }
          .date {
            font-size: 12px;
            font-family: Microsoft YaHei;
            color: #999999;
          }
        }
      }
    }
  }
  .moreBtn {
    width: 184px;
    height: 47px;
    line-height: 47px;
    border: 1px solid #707070;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    color: #333333;
    margin: 45px auto 0;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(112, 112, 112, 0.5);
      color: rgba(51, 51, 51, 0.5);
    }
    img {
      display: inline-block;
      margin-left: 21px;
    }
  }
}
</style>
