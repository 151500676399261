<template>
  <div class="sectionFive">
    <div class="main_width">
      <div class="data_content" ref="data_content">
        <h3 class="queue-bottom">为什么选择企业慧点</h3>
        <div class="subtitle queue-bottom animate_delay01">
          选择慧点，为企业降本提效
        </div>
        <ul class="select_infor">
          <li class="infor_list" v-for="(item, index) in arrList" :key="index">
            <div class="title queue-bottom animate_delay02">
              {{ item.title }}
            </div>
            <img class="queue-bottom animate_delay03" :src="item.pic" alt="" />
            <p class="content queue-bottom animate_delay04">
              {{ item.content }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      arrList: [
        {
          title: '政策信息',
          pic: require('@/assets/images/home/policy_infor.png'),
          content: '企业慧点汇集超过1000+条适用于深圳企业的政府扶持政策和措施'
        },
        {
          title: '招标信息',
          pic: require('@/assets/images/home/bidding_infor.png'),
          content: '6万条招标信息，为企业提供及时、准确的招投标信息，帮助企业轻松筛选适合的标讯，把握商机。'
        },
        {
          title: '融资产品',
          pic: require('@/assets/images/home/finance_product.png'),
          content: '十多个融资产品线上对接，配套贴息政策解读，解决企业融资难、融资贵、融资繁的问题'
        },
        {
          title: '企业服务',
          pic: require('@/assets/images/home/enterprise_serve.png'),
          content: '严格甄选30多项企业服务，为企业从成立到壮大全流程提供咨询及方案的落地实施，助力企业成长壮大。'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = e.target.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop >= 2200) {
        this.$refs.data_content.style.display = 'block'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 第五模块
.sectionFive {
  width: 100%;
  height: 824px;
  background: url("~assets/images/home/sel_enterprise_bg.png") no-repeat 50% 50%;
  padding-top: 134px;
  .main_width {
    width: 1366px;
    text-align: center;
  }
  .data_content {
    display: none;
  }
  h3 {
    font-size: 60px;
    font-weight: 500;
    color: #ffffff;
  }
  .subtitle {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 15px;
  }
  .select_infor {
    width: 100%;
    text-align: left;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    .infor_list {
      width: 280px;
      .title {
        font-size: 32px;
        color: #cccccc;
        margin-bottom: 29px;
      }
      .content {
        font-size: 14px;
        line-height: 30px;
        color: #c4c4c4;
        margin-top: 47px;
      }
    }
  }
}
@media (max-width: 1366px) {
  .sectionFive {
    .main_width {
      width: 1100px !important;
      .select_infor {
        .infor_list {
          width: 230px !important;
        }
      }
    }
  }
}
</style>
