<template>
  <div class="head_bg">
    <div class="main_width">
      <div class="middle">
        <div class="head_title">
          <h1 class="queue-right">找补贴找业务<br />找企业慧点</h1>
          <span class="queue-right">与小微企业共成长</span>
        </div>
        <div class="head_pic">
          <img
            class="pic1 layer"
            data-speed="3"
            src="@/assets/images/home/head_pic1.png"
            alt=""
          />
          <img
            class="pic2 layer"
            data-speed="4"
            src="@/assets/images/home/head_pic2.png"
            alt=""
          />
          <img
            class="pic3 layer"
            data-speed="5"
            src="@/assets/images/home/head_pic3.png"
            alt=""
          />
          <img
            class="pic4 layer"
            data-speed="6"
            src="@/assets/images/home/head_pic4.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    document.addEventListener('mousemove', this.parallax)
  },
  methods: {
    // 视差滚动
    parallax (e) {
      document.querySelectorAll('.layer').forEach(item => {
        const speed = item.getAttribute('data-speed')
        const x = (window.innerWidth - e.pageX * speed) / 70
        const y = (window.innerHeight - e.pageY * speed) / 70
        item.style.transform = `translateX(${x}px) translateY(${y}px)`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 第一模块
.layer {
  transition: all 0.1s;
}
.head_bg {
  width: 100%;
  height: 1020px;
  background: url('~assets/images/home/bg_img.png') no-repeat 50% 50%;
  background-size: cover;
  .middle {
    position: relative;
    padding-top: 173px;
    padding-left: 50px;
    .head_title {
      position: absolute;
      padding-top: 50px;
      h1 {
        font-size: 67px;
        font-weight: 500;
        color: #dbbb95;
        animation-delay: 1.5s;
      }
      span {
        display: inline-block;
        font-size: 26px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 20px;
        margin-top: 24px;
        animation-delay: 1.6s;
      }
    }
    .head_pic {
      width: 861px;
      position: relative;
      top: 0;
      left: 35%;
      height: 900px;
      .pic1,
      .pic2,
      .pic3,
      .pic4 {
        position: absolute;
      }
      .pic1 {
        width: 861px;
        height: 604px;
        left: 0;
        top: 0;
        z-index: 2;
      }
      .pic2 {
        width: 537px;
        height: 225px;
        left: 60px;
        top: 400px;
        z-index: 3;
      }
      .pic3 {
        width: 397px;
        height: 465px;
        left: 310px;
        top: 42px;
      }
      .pic4 {
        width: 126px;
        height: 111px;
        left: 610px;
        top: 221px;
      }
    }
  }
}
@media (max-width: 1450px) {
  .head_pic {
    left: 30% !important;
  }
}
</style>
